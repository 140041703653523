import React, {useEffect} from 'react';
import {Navigate, useLocation, useNavigate} from 'react-router-dom';

import {useAuth} from '../../hooks';
import {ROUTS} from 'constants';

const TEST_STRING = 'user-test';

export const PublicRoute = ({component: Component, redirectTo = '/'}) => {
    const {isLoggedIn} = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const saveUrlParamsToSession = () => {
            const params = new URLSearchParams(location.search);

            if (params.has('organization') && params.has(TEST_STRING) && !isLoggedIn) {
                navigate(ROUTS.register);
            }

            params.forEach((value, key) => {
                if (TEST_STRING === key) {
                    sessionStorage.setItem(key, `/${key}/${value}`);

                    return;
                }

                sessionStorage.setItem(key, value);
            });
        };

        saveUrlParamsToSession();
    }, [location.search]);

    return isLoggedIn ? <Navigate to={redirectTo} /> : Component;
};
