export * from './validationSchema/index';
export * from './sort/index';

const pathTypes = ['user-test', 'user-checked', 'dashboard'];
const pathTypesClearIgnore = ['user-test'];

export const getUrlExtension = url => {
    return url.split(/[#?]/)[0].split('.').pop().trim();
};

export const onImageEdit = async imgUrl => {
    const imgExt = getUrlExtension(imgUrl);
    const response = await fetch(imgUrl);

    const blob = await response.blob();

    return new File([blob], 'profileImage.' + imgExt, {
        type: blob.type,
    });
};

export const saveDataBeforeRedirect = () => {
    const {pathname} = window.location;
    const pathType = pathTypes.filter(item => pathname.includes(item));

    sessionStorage.clear();

    if (pathType?.length > 0) {
        sessionStorage.setItem(pathType, pathname);
    }
};

export const userAuthRedirect = navigate => {
    const storageData = pathTypes.map(item => sessionStorage.getItem(item));
    const existedStorageData = storageData.filter(item => item !== null);

    const handleNavigation = path => {
        setTimeout(() => {
            navigate(path);

            if (pathTypesClearIgnore.some(item => path.includes(item))) {
                return;
            }

            sessionStorage.clear();
        }, 100);
    };

    if (existedStorageData.length > 0) {
        handleNavigation(existedStorageData[0]);
    }
};
